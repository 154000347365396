<template>
	<b-modal
		id="force-logout"
		title="Force Logout"
		@ok="onForceLogoutUserSession"
		:no-close-on-backdrop="true"
		centered
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>
		<p>
			We've detected that you have a current session on other device. Do you
			want them to force logout?
		</p>

		<template v-slot:modal-footer>
			<b-button @click="onCancel" :disabled="isLoading"> Cancel </b-button>
			<b-button
				type="submit"
				variant="primary"
				@click="onForceLogoutUserSession"
				:disabled="isLoading"
			>
				Okay
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'ForceLogout',
	components: { Loading },
	props: {
		email: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			isLoading: false,
		};
	},
	methods: {
		async onForceLogoutUserSession(evt) {
			evt.preventDefault();
			this.isLoading = true;
			try {
				await this.$store.dispatch('logout');
				let previousState = this.$store.getters.previousState;
				if (!_.isEmpty(previousState)) {
					this.$store.replaceState(previousState);
				}

				this.$bvModal.hide('force-logout');
				this.$toaster.success(
					'Successfully logged out. You may now try logging in!'
				);
			} catch (error) {
				this.$toaster.error('Oops! Something went wrong, please try again.');
			}
			// hide loading indicator
			this.isLoading = false;
		},
		onCancel() {
			this.$bvModal.hide('force-logout');
		},
	},
};
</script>